import { SHORTLY, NOW, END, ERROR } from '@/constants/campaign'
import dayjs from 'dayjs'

export function useCampaign() {
  const setHoldStatus = (startDate: string, endDate: string) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const now = new Date()

    if (start > now) {
      return SHORTLY
    } else if (now >= start && now < end) {
      return NOW
    } else if (now >= start && dayjs(now).diff(end, 'day') <= 31) {
      return END
    } else {
      return ERROR
    }
  }

  return {
    setHoldStatus,
  }
}
