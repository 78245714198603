import { END } from '@/constants/campaign'
import { Campaign, HoldStatus } from '@/interfaces/campaign'
import dayjs from 'dayjs'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { HoldStatusBadge } from '../elements/HoldStatusBadge'
import { Title } from '../elements/Title'

type CampaignItemsProps = Omit<Campaign, 'campaignbody'> & {
  holdStatus: HoldStatus
}

export const CampaignItems = ({
  campaignId,
  eyecatch,
  startdate,
  enddate,
  title,
  holdStatus,
}: CampaignItemsProps) => {
  return (
    <CampaignItem holdStatus={holdStatus}>
      <CampaignLink to={`/campaign/${campaignId}/`}>
        <img src={eyecatch.url} />
        <CampaignItemInner>
          <HoldStatusBadge holdStatus={holdStatus} />
          <p>
            {dayjs(startdate).format('YYYY/MM/DD(dd)')}〜
            {dayjs(enddate).format('YYYY/MM/DD(dd)')}
          </p>
          <Title as="h3" size={24}>
            {title}
          </Title>
        </CampaignItemInner>
      </CampaignLink>
    </CampaignItem>
  )
}

const CampaignItem = styled.li<{ holdStatus: number }>`
  opacity: ${({ holdStatus }) => (holdStatus === END ? '0.5' : '')};

  &:hover {
    opacity: ${({ holdStatus, theme }) =>
      holdStatus !== END ? theme.opacity : '0.3'};
  }

  img {
    width: 300px;
    border-radius: 20px;
  }

  & + & {
    margin-top: 50px;
  }

  @media screen and (max-width: 1047px) {
    img {
      width: 100%;
    }

    & + & {
      margin-top: 44px;
    }
  }
`

const CampaignItemInner = styled.div`
  margin-left: 50px;

  p:nth-child(2) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 24px;
  }

  h3 {
    text-align: left;
    line-height: 32px;
  }

  @media screen and (max-width: 1047px) {
    margin-left: 0;
    margin-top: 16px;
    text-align: center;

    p:nth-child(2) {
      font-size: 16px;
      margin-bottom: 17px;
      text-align: center;
      line-height: 16px;
    }

    h3 {
      font-size: 20px;
      line-height: 25px;
      text-align: center;
    }
  }
`

const CampaignLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.colors.textColor};

  @media screen and (max-width: 1047px) {
    flex-direction: column;
    align-items: center;
  }
`
