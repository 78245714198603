import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { theme } from '@/styles/theme'
import { Layout } from '@/components/layouts/Layout'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Seo } from '@/components/Seo'

import { CampaignQuery, HoldStatus } from '@/interfaces/campaign'
import { CampaignItems } from '@/components/layouts/CampaignItems'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { useCampaign } from '@/hooks/useCampaign'
import { ERROR } from '@/constants/campaign'

export const query = graphql`
  query {
    allMicrocmsCampaign(sort: { order: DESC, fields: startdate }) {
      edges {
        node {
          campaignId
          title
          startdate
          enddate
          eyecatch {
            url
          }
        }
      }
    }
  }
`

type Query = {
  allMicrocmsCampaign: CampaignQuery
}

export default function faq({ data }: { data: Query }): JSX.Element {
  const breadCrumbs = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'キャンペーン',
    },
  ]

  const { setHoldStatus } = useCampaign()

  return (
    <Layout>
      <Seo
        title="キャンペーン"
        canonical="https://su-pay.jp/campaign/"
        description="SU-PAYのおトクなキャンペーン情報をご紹介します。"
      />
      <Title as="h1" bg={true}>
        キャンペーン
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper spMarginBottom={30}>
          <Title as="h2" color={theme.colors.supayBlue}>
            キャンペーン一覧
          </Title>
        </TitleWrapper>
        <CampaignList>
          {data.allMicrocmsCampaign.edges
            .map((campaign) => {
              const holdStatus: HoldStatus = setHoldStatus(
                campaign.node.startdate,
                campaign.node.enddate
              )
              return {
                campaign,
                holdStatus,
              }
            })
            .filter(
              (statusHasCampaign) => statusHasCampaign.holdStatus !== ERROR
            )
            .sort((pre, next) => pre.holdStatus - next.holdStatus)
            .map(({ campaign, holdStatus }) => (
              <CampaignItems
                key={campaign.node.campaignId}
                campaignId={campaign.node.campaignId}
                holdStatus={holdStatus}
                eyecatch={campaign.node.eyecatch}
                startdate={campaign.node.startdate}
                enddate={campaign.node.enddate}
                title={campaign.node.title}
              />
            ))}
        </CampaignList>
      </MainWrapper>
    </Layout>
  )
}

const CampaignList = styled.ul``
