import styled from 'styled-components'

export const MainWrapper = styled.div`
  width: ${({ theme }) => theme.containerWidth};
  margin: 30px auto 0;

  @media screen and (max-width: 1047px) {
    width: 100%;
    padding: 0 ${({ theme }) => theme.containerMargin};
    margin: 12px auto 0;
  }
`

export const TitleWrapper = styled.div<{
  pcMarginTop?: number
  spMarginTop?: number
  pcMarginBottom?: number
  spMarginBottom?: number
  textAlign?: string
}>`
  padding: ${({ pcMarginTop, pcMarginBottom }) =>
    `${pcMarginTop ?? 102}px 0 ${pcMarginBottom ?? 70}px`};

  h2 {
    line-height: 1em;
    text-align: ${({ textAlign }) => `${textAlign ?? 'left'}`};
  }

  @media screen and (max-width: 1047px) {
    padding: ${({ spMarginTop, spMarginBottom }) =>
      `${spMarginTop ?? 46}px 0 ${spMarginBottom ?? 22}px`};

    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`
