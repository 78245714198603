import React from 'react'
import styled from 'styled-components'

import { SHORTLY, NOW, END } from '@/constants/campaign'
import { HoldStatus } from '@/interfaces/campaign'

type HoldStatusBadgeProps = {
  holdStatus: HoldStatus
}

export const HoldStatusBadge = ({ holdStatus }: HoldStatusBadgeProps) => {
  switch (holdStatus) {
    case SHORTLY:
      return <Badge data-hold-shortly>まもなく開催</Badge>
    case NOW:
      return <Badge data-hold-now>開催中</Badge>
    case END:
      return <Badge data-hold-end>開催終了</Badge>
    default:
      return <></>
  }
}

const Badge = styled.p`
  display: inline-block;
  font-size: 17px;
  line-height: 1em;
  font-weight: bold;
  padding: 7px 10px;
  border-radius: 10px;
  margin-bottom: 12px;

  &[data-hold-shortly] {
    background-color: rgba(0, 70, 155, 0.1);
    color: ${({ theme }) => theme.colors.supayBlue};
  }

  &[data-hold-now] {
    background-color: ${({ theme }) => theme.colors.supayBlue};
    color: #fff;
  }

  &[data-hold-end] {
    background-color: ${({ theme }) => theme.colors.textColor};
    color: #fff;
  }

  @media screen and (max-width: 1047px) {
    font-size: 16px;
    padding: 6px 10px;
    margin-bottom: 8px;
  }
`
